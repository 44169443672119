<template>
    <div class="layout default-layout">
        <Navbar />
        <main class="mainContent">
            <slot />
        </main>
        <Footer />
    </div>
</template>

<script>
import Navbar from './Navbar'
import Footer from './Footer'

export default {
    name: 'Default',
    components: { Navbar, Footer },
}
</script>
