import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import Buefy from 'buefy'
import { Api } from './plugins/api'
import { Toast } from './plugins/toast'
import { Loading } from './plugins/loading'
import DefaultLayout from '@/components/Layout/Default'
import './assets/scss/app.scss'
import './filters'

Vue.config.productionTip = false

Vue.use(Buefy, {
    defaultIconPack: 'fas',
})

Vue.use(Api)
Vue.use(Toast)
Vue.use(Loading)

Vue.component('DefaultLayout', DefaultLayout)

export default new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
