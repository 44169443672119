export const googleAuth = {
    data() {
        return {
            idToken: null,
            accounts: [],
            showAccountSelectModal: false,
        }
    },
    methods: {
        async signup(idToken) {
            const {
                success,
                data,
                message,
            } = await this.$api.post('/v1/account/google-signup', { idToken })
            if (success) {
                this.$store.dispatch('account/login', data)
                this.$router.push({ name: 'account.dashboard' })
                setTimeout(() => {
                    this.$buefy.dialog.confirm({
                        cancelText: 'View Dashboard',
                        confirmText: 'Open Guide',
                        sxize: 'is-medium',
                        message: `
                        <i class="fas fa-glass-cheers"></i>
                        <b-icon icon="glass-cheers"></b-icon>
                        <h2>Welcome to SessionScoop!</h2>
                        <p>Let's get started. Explore our setup guide or jump right into your dashboard.</p>
                    `,
                        onConfirm: () => {
                            this.$router.push({ name: 'docs' })
                            return true
                        },
                    })
                }, 1000)
            } else {
                this.$toast.error(message)
            }
        },
        async login(idToken) {
            this.idToken = idToken
            const { success, data, message } = await this.$api.post(
                '/v1/account/google-login',
                {
                    idToken,
                }
            )
            if (success) {
                if (data.token) {
                    this.$store.dispatch('account/login', data)
                    this.$router.push({ name: 'account.dashboard' })
                } else {
                    this.accounts = data.accounts
                    this.showAccountSelectModal = true
                }
            } else {
                this.$toast.error(message)
            }
        },
        async loginWithAccount(accountId) {
            this.showAccountSelectModal = false
            this.$loading.show()
            const { success, data } = await this.$api.post(
                '/v1/account/google-login/account-select',
                {
                    accountId,
                    idToken: this.idToken,
                }
            )
            this.$loading.hide()
            if (success) {
                this.$store.dispatch('account/login', data)
                this.$router.push({ name: 'account.dashboard' })
            } else {
                this.$toast.error()
            }
        },
        logout() {
            this.$store.dispatch('account/logout')
            this.$router.push({ name: 'home' })
        },
    },
}
