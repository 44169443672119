export default {
    namespaced: true,
    state: {
        events: [],
        currentTime: 0,
    },
    getters: {
        currentTime: state => state.currentTime,
        events: state => state.events,
    },
    mutations: {
        SET_STATE(state, value) {
            Object.assign(state, value)
        },
    },
    actions: {
        setCurrentTime({ commit, state }, currentTime) {
            commit('SET_STATE', {
                ...state,
                currentTime,
            })
        },
        setCurrentEvent({ commit, state }, currentEvent) {
            commit('SET_STATE', {
                ...state,
                events: [...state.events, currentEvent],
            })
        },
    },
}
