export const Toast = {
    install(Vue) {
        Vue.prototype.$toast = {
            success(message) {
                Vue.prototype.$buefy.toast.open({
                    message,
                    type: 'is-success',
                    duration: 5000,
                })
            },
            error(message) {
                Vue.prototype.$buefy.toast.open({
                    message: message || `Oops, that didn't work. Try again.`,
                    type: 'is-danger',
                    duration: 5000,
                })
            },
        }
    },
}
