import Vue from 'vue'
import Vuex from 'vuex'
import account from './modules/account'
import player from './modules/player'
import { persistState } from './plugins/persistState'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        account,
        player,
    },
    plugins: [persistState],
})
