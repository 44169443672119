<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
export default {
    name: 'App',
    created() {
        this.$store.getters['account/loggedIn'] &&
            window.location.pathname === '/' &&
            this.$router.push({ name: 'account.dashboard' })
    },
}
</script>
