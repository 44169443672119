import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
})

router.beforeEach(async function(to, from, next) {
    if (to.meta.requiresAuth && !store.getters['account/loggedIn']) {
        return next({ name: 'login' })
    }
    next()
})

export default router
