export default [
    {
        path: '/',
        name: 'home',
        component: () => import('@/pages/home'),
    },
    {
        path: '/contact',
        name: 'contact',
        component: () => import('@/pages/contact'),
    },
    {
        path: '/docs',
        name: 'docs',
        component: () => import('@/pages/docs'),
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/pages/login'),
    },
    {
        path: '/signup',
        name: 'signup',
        component: () => import('@/pages/signup'),
    },
    {
        path: '/dashboard',
        name: 'account.dashboard',
        component: () => import('@/pages/account/dashboard'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/recordings',
        name: 'account.recs',
        component: () => import('@/pages/account/recs/recs'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/watch/:id/:time?',
        name: 'account.recs.show',
        component: () => import('@/pages/account/recs/rec'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/account/settings',
        name: 'account.settings',
        component: () => import('@/pages/account/settings/profile'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/account/settings/sites',
        name: 'account.settings.sites',
        component: () => import('@/pages/account/settings/sites'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/account/settings/script',
        name: 'account.settings.script',
        component: () => import('@/pages/account/settings/script'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/account/team/',
        name: 'account.users',
        component: () => import('@/pages/account/users'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        // 404
        path: '/*',
        name: '404',
        component: () => import('@/pages/error/404'),
    },
]
