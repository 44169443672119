<template>
    <footer class="mainFooter footer">
        <ul>
            <li>
                <router-link :to="{ name: 'home' }">Home</router-link>
            </li>
            <li v-if="!loggedIn">
                <router-link :to="{ name: 'login' }">Log in</router-link>
            </li>
            <li v-if="!loggedIn">
                <router-link :to="{ name: 'signup' }">Sign up</router-link>
            </li>
            <li>
                <router-link :to="{ name: 'docs' }">Documentation</router-link>
            </li>
            <li>
                <router-link :to="{ name: 'contact' }">Contact</router-link>
            </li>
        </ul>
        <p class="mt-3">&copy; {{ year }} SessionScoop.com</p>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    data() {
        return {
            year: new Date().getFullYear(),
        }
    },
    computed: {
        loggedIn() {
            return this.$store.getters['account/loggedIn']
        },
    },
}
</script>
