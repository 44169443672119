export const Loading = {
    install(Vue) {
        let loading = null
        Vue.prototype.$loading = {
            show() {
                this.hide()
                loading = Vue.prototype.$buefy.loading.open({
                    container: null,
                })
            },
            hide() {
                loading && loading.close()
            },
        }
    },
}
