import axios from 'axios'
import app from '@/main'

const http = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
})

http.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

async function call({ url, method, data = {} }) {
    const headers = {}
    const authToken = app.$store.getters['account/authToken']
    if (authToken) {
        headers['Token'] = authToken
        headers['TimeZone'] = Intl.DateTimeFormat().resolvedOptions().timeZone
    }
    try {
        const response = await http({
            method,
            url,
            data,
            headers,
        })
        return response.data
    } catch ({ response }) {
        if (response) {
            const { status, data } = response
            if (status == 401 && data.code !== 'bad_login') {
                app.$store.dispatch('account/logout')
                app.$router.push({ name: 'login' })
                app.$toast.error('Session expired.')
            }
            return {
                ...data,
                message:
                    data.message || `Oops! Error ${status} &ndash; try again!`,
            }
        }
        return {
            success: false,
            message: 'Oops! Something went wrong &ndash; try again!',
        }
    }
}

async function get(url) {
    return await call({ url, method: 'GET' })
}

async function post(url, data) {
    return await call({ url, data, method: 'POST' })
}

export const api = { get, post }
