import Vue from 'vue'

Vue.filter('millisToHhMmSs', function(millis) {
    if (millis === 0) {
        return '00:00'
    }
    const secs = parseInt((millis / 1000) % 60)
    const mins = parseInt((millis / (1000 * 60)) % 60)
    const hours = parseInt((millis / (1000 * 60 * 60)) % 24)
    const minsPadded = mins < 10 ? '0' + mins : mins
    const secsPadded = secs < 10 ? '0' + secs : secs
    const pieces = [minsPadded, secsPadded]
    hours && pieces.unshift(hours < 10 ? '0' + hours : hours)
    return pieces.join(':')
})
