import { authCookie } from '@/utils/authCookie'

export const persistState = store => {
    store.subscribe((mutation, state) => {
        switch (mutation.type) {
            case 'account/RESET_STATE':
                authCookie.remove()
                break
            case 'account/SET_AUTH_USER':
                authCookie.set(state.account.authUser)
                break
        }
    })
}
