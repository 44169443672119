import { api } from '@/services/api'
import { authCookie } from '@/utils/authCookie'

const DATA_TTL_MILLIS = 2 * 60 * 1000 // 2 mins

const createInitialState = () => ({
    authUser: null,
    stats: {
        lastLoaded: 0,
        data: {},
    },
    users: {
        lastLoaded: 0,
        data: [],
    },
    recs: {
        lastLoaded: 0,
        data: [],
    },
})

const authUser = authCookie.get()
const initialState = { ...createInitialState(), authUser }

export default {
    namespaced: true,
    state: initialState,
    getters: {
        loggedIn: ({ authUser }) => !!authUser,
        authToken: ({ authUser }) => authUser && authUser.token,
        user: ({ authUser }) => authUser,
        accountId: ({ authUser }) => authUser && authUser.accountId,
        stats: ({ stats }) => stats.data,
        users: ({ users }) => users.data,
        recs: ({ recs }) => recs.data,
    },
    mutations: {
        RESET_STATE(state) {
            Object.assign(state, createInitialState())
        },
        SET_AUTH_USER: function(state, data) {
            state.authUser = data
        },
        SET_STATS: function(state, data) {
            state.stats = data
        },
        SET_RECS: function(state, data) {
            state.recs = data
        },
        SET_USERS: function(state, data) {
            state.users = data
        },
        ADD_USER: function(state, user) {
            state.users.data.push(user)
        },
        UPDATE_USER: function(state, user) {
            const index = state.users.data.findIndex(({ id }) => id === user.id)
            state.users.data.splice(index, 1, user)
        },
    },
    actions: {
        async login({ commit }, data) {
            commit('SET_AUTH_USER', data)
        },
        logout({ commit }) {
            api.post('/v1/account/logout')
            commit('RESET_STATE')
            try {
                window.gapi.auth2.getAuthInstance().disconnect()
            } catch (e) {
                //
            }
        },
        async updateAuthUser({ commit, state }, { name, email, accountName }) {
            const { authUser } = state
            commit('SET_AUTH_USER', {
                ...authUser,
                name,
                email,
                accountName,
            })
            if (state.users.lastLoaded) {
                const user = state.users.data.find(
                    ({ id }) => id === authUser.id
                )
                user &&
                    commit('UPDATE_USER', {
                        ...user,
                        name,
                        email,
                    })
            }
        },
        async loadStats({ commit, state }) {
            const { stats } = state
            if (Date.now() - stats.lastLoaded < DATA_TTL_MILLIS) {
                return { success: true }
            }
            const { success, data, message } = await api.get(
                '/v1/account/stats'
            )
            success &&
                commit('SET_STATS', {
                    data,
                    lastLoaded: Date.now(),
                })
            return { success, message }
        },
        async loadRecs({ commit, state }) {
            const { recs } = state
            if (Date.now() - recs.lastLoaded < DATA_TTL_MILLIS) {
                return { success: true }
            }
            const { success, data, message } = await api.get('/v1/account/recs')
            success &&
                commit('SET_RECS', {
                    data,
                    lastLoaded: Date.now(),
                })
            return { success, message }
        },
        async loadUsers({ commit, state }) {
            const { users } = state
            if (Date.now() - users.lastLoaded < DATA_TTL_MILLIS) {
                return { success: true }
            }
            const { success, data, message } = await api.get(
                '/v1/account/users'
            )
            success &&
                commit('SET_USERS', {
                    data,
                    lastLoaded: Date.now(),
                })
            return { success, message }
        },
        async addUser({ commit }, data) {
            const response = await api.post('/v1/account/users', data)
            response.success && commit('ADD_USER', response.data)
            return response
        },
        async updateUser({ commit, state }, { id, data }) {
            const response = await api.post(`/v1/account/users/${id}`, data)
            if (response.success) {
                commit('UPDATE_USER', response.data)
                id === state.authUser.id &&
                    commit('SET_AUTH_USER', {
                        ...state.authUser,
                        name: response.data.name,
                        email: response.data.email,
                    })
            }
            return response
        },
    },
}
