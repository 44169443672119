import Cookies from 'js-cookie'

const NAME = 'sessionscoop'
const EXPIRES_MINUTES = 120

function get() {
    try {
        const valueBase64 = Cookies.get(NAME)
        const value = JSON.parse(window.atob(valueBase64))
        return Object.keys(value).length ? value : null
    } catch (e) {
        return null
    }
}

function set(value) {
    Cookies.set(
        NAME,
        window.btoa(
            JSON.stringify({
                ...value,
            })
        ),
        {
            secure: process.env.NODE_ENV === 'production',
            expires: new Date(Date.now() + EXPIRES_MINUTES * 60 * 1000),
        }
    )
}

function remove() {
    return Cookies.remove(NAME)
}

export const authCookie = {
    get,
    set,
    remove,
}
