<template>
    <b-navbar class="mainNav">
        <template slot="brand">
            <b-navbar-item
                tag="router-link"
                :to="{ name: loggedIn ? 'account.dashboard' : 'home' }"
            >
                <i class="fas fa-play-circle"></i>SessionScoop
            </b-navbar-item>
        </template>
        <template slot="end">
            <b-navbar-item
                v-if="!loggedIn"
                tag="router-link"
                :to="{ name: 'home' }"
            >
                Home
            </b-navbar-item>
            <b-navbar-item
                v-if="loggedIn"
                tag="router-link"
                :to="{ name: 'account.dashboard' }"
            >
                <b-icon
                    icon="tachometer-alt"
                    class="has-text-info mr-1"
                ></b-icon>
                Dashboard
            </b-navbar-item>
            <b-navbar-item
                v-if="loggedIn"
                tag="router-link"
                :to="{ name: 'account.recs' }"
            >
                <b-icon icon="play" class="has-text-primary mr-1"></b-icon>
                Recordings
            </b-navbar-item>
            <b-navbar-item tag="router-link" :to="{ name: 'docs' }">
                <b-icon icon="book" class="has-text-danger mr-1"></b-icon>
                Documentation
            </b-navbar-item>
            <b-navbar-dropdown class="userMenu" v-if="loggedIn">
                <template slot="label">
                    <b-icon icon="user"></b-icon>
                    {{ user.name.toLowerCase() }}
                </template>
                <b-navbar-item
                    tag="router-link"
                    :to="{ name: 'account.settings' }"
                >
                    Account Settings
                </b-navbar-item>
                <b-navbar-item
                    tag="router-link"
                    :to="{ name: 'account.users' }"
                >
                    Team
                </b-navbar-item>
                <b-navbar-item @click="logout">
                    Log out
                </b-navbar-item>
            </b-navbar-dropdown>
            <b-navbar-item
                v-if="!loggedIn"
                tag="router-link"
                :to="{ name: 'signup' }"
            >
                Sign up
            </b-navbar-item>
            <b-navbar-item
                v-if="!loggedIn"
                tag="router-link"
                :to="{ name: 'login' }"
            >
                Log in
            </b-navbar-item>
        </template>
    </b-navbar>
</template>

<script>
import { googleAuth } from '~/mixins/googleAuth'
import { mapGetters } from 'vuex'

export default {
    name: 'Navbar',
    mixins: [googleAuth],
    computed: {
        ...mapGetters({ user: 'account/user', loggedIn: 'account/loggedIn' }),
    },
}
</script>
